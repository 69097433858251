angular
    .module('madApp')
    .directive('consolidatedSummary', consolidatedSummaryDirective);

function consolidatedSummaryDirective() {
    var directive = {
        restrict: 'A',
        templateUrl: 'app/vl/dashboards/directives/consolidated/consolidated.summary.html',
        scope: {
            totalSummary: "=totalSummary",
            throb:"=throb",
            currencyUnits: "=currencyUnits"
        },
        link: linkFunc,
    };

    return directive;

    function linkFunc(scope, element, attrs) {
        scope.parseFloat = parseFloat;
    }
}
