(function () {
    'use strict';

    angular
        .module('madApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('home',{
                parent : 'app',
                url : '/',
                redirectTo : 'vl.dashboards.revenue'
            })
/*            .state('home', {
                parent: 'app',
                url: '/',
                data: {
                    authorities: ['ROLE_USER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/home/home.html',
                        controller: 'HomeController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            })*/
            // .state('zone', {
            //     parent: 'app',
            //     url: '/:type/:id',
            //     //reloadOnSearch: false,
            //     data: {
            //         authorities: ['ROLE_USER']
            //     },
            //     params : {fromDate:null, toDate:null},
            //     views: {
            //         'content@': {
            //             templateUrl: 'app/home/home.html',
            //             controller: 'HomeController',
            //             controllerAs: 'vm'
            //         }
            //     },
            //     resolve: {
            //         mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
            //             $translatePartialLoader.addPart('home');
            //             return $translate.refresh();
            //         }]
            //     }
            // });
    }

    // angular
    //     .module('madApp')
    //     .directive('revenueSummary', revenueSummaryDirective)
    //
    // revenueSummaryDirective.$inject = [];
    //
    // function revenueSummaryDirective() {
    //     var directive = {
    //         restrict: 'A',
    //         templateUrl: 'app/home/summary.html',
    //         scope: {
    //             title: "@title",
    //             icon: "&icon",
    //             summary: "=summary"
    //         },
    //         link: linkFunc,
    //         controller: 'RevenueSummaryDirectiveController',
    //
    //     };
    //
    //     return directive;
    //
    //     function linkFunc(scope, element, attrs) {
    //         scope.icon = attrs.icon;
    //         scope.title = attrs.title;
    //     }
    // }
    //
    // angular
    //     .module('madApp')
    //     .controller('RevenueSummaryDirectiveController', RevenueSummaryDirectiveController)
    // ;
    //
    // RevenueSummaryDirectiveController.$inject = ['$scope', '$state', '$stateParams', '$filter', 'ParseLinks', 'AlertService'];
    // function RevenueSummaryDirectiveController($scope, $state, $stateParams, $filter, ParseLinks, AlertService) {
    //     var rsdc = this;
    //     rsdc.name = "test";
    // }
    //
    // angular
    //     .module('madApp')
    //     .directive('revenueDetail', revenueDetailDirective);
    //
    //
    //
    // function revenueDetailDirective() {
    //     var directive = {
    //         restrict: 'A',
    //         templateUrl: 'app/home/revenue.detail.template.html',
    //         link: linkFunc,
    //         controller: 'RevenueDetailDirectiveController',
    //         scope: {
    //             title: "@title",
    //             icon: "&icon",
    //             chartData: "=chartData",
    //             revenueDetails: "=revenueDetails",
    //             summary: "=summary",
    //             totalSummary: "=totalSummary",
    //             ulbList: '=ulbList',
    //             chartopt:"=chartopt",
    //             graphid:"=graphId",
    //             key:"=key",
    //             type:"=type",
    //             callfunc:"&callfunc"
    //         }
    //
    //     };
    //     return directive;
    //
    //
    //     function linkFunc(scope, element, attrs) {
    //         scope.icon = attrs.icon;
    //         scope.title = attrs.title;
    //     }
    //
    // }
    //
    // angular
    //     .module('madApp')
    //     .controller('RevenueDetailDirectiveController', RevenueDetailDirectiveController)
    // ;
    //
    // RevenueDetailDirectiveController.$inject = ['$scope', '$state', '$stateParams', '$filter', '$translate', '$translatePartialLoader', 'ParseLinks', 'AlertService','StateRevenueService'];
    // function RevenueDetailDirectiveController($scope, $state, $stateParams, $filter, $translate, $translatePartialLoader, ParseLinks, AlertService, StateRevenueService) {
    //     $translatePartialLoader.addPart('vassar');
    //     $translate.refresh();
    //     /**
    //      * Mont constant, fixed to April of this year
    //      *
    //      * @type {Date}
    //      */
    //     var minMonth = new Date();
    //     minMonth.setMonth(3); //TODO: Constant - make it configurable
    //     minMonth.setDate(1);
    //     /**
    //      * Query data to be populated from the UI.
    //      * @type {{fromDate: null, toDate: null, gradeType: string, grade: null, selectedULB: null}}
    //      */
    //     $scope.queryData = {
    //         fromDate: null,
    //         toDate: null,
    //         gradeType: 'district',
    //         grade: null,
    //         selectedULB: null
    //     };
    //
    //     $scope.formHidden = true;
    //     /**
    //      * toggle form
    //      */
    //     $scope.toggleForm = function () {
    //         $scope.formHidden = !$scope.formHidden;
    //     };
    //     // Date picker format
    //     $scope.format = 'shortDate';
    //     $scope.fromDate = {
    //         opened: false
    //     };
    //
    //     $scope.toDate = {
    //         opened: false
    //     };
    //     /**
    //      * From date picker configuration
    //      * @type {{minDate: Date, maxDate: Date, datepickerMode: string, maxMode: string, minMode: string}}
    //      */
    //     $scope.fromDateOptions = {
    //         minDate: minMonth,
    //         maxDate: new Date(),
    //         datepickerMode: 'day',
    //         maxMode: 'day',
    //         minMode: 'day'
    //     };
    //     /**
    //      * To date picker configuration
    //      * @type {{minDate: Date, maxDate: Date, datepickerMode: string, maxMode: string, minMode: string}}
    //      */
    //     $scope.toDateOptions = {
    //         minDate: $scope.queryData.fromDate || minMonth,
    //         maxDate: new Date(),
    //         datepickerMode: 'day',
    //         maxMode: 'day',
    //         minMode: 'day'
    //     };
    //     /**
    //      * Open from-date date-picker popup.
    //      */
    //     $scope.fromDatePickerOpen = function () {
    //         $scope.fromDate.opened = true;
    //     };
    //     /**
    //      * Open the to-date date-picker popup.
    //      */
    //     $scope.toDatePickerOpen = function () {
    //         $scope.toDate.opened = true;
    //     };
    //
    //
    //     $scope.$watch('queryData.fromDate', function (newValue, oldValue) {
    //         if (newValue) {
    //             $scope.toDateOptions.minDate = newValue;
    //         }
    //     });
    //
    //     $scope.$watch('queryData.gradeType', function (newValue, oldValue) {
    //         if (newValue) {
    //             $scope.queryData.grade = null;
    //             $scope.queryData.selectedULB = null;
    //         }
    //     });
    //     $scope.$watch('queryData.grade', function (newValue, oldValue) {
    //         if (newValue) {
    //             $scope.queryData.selectedULB = null;
    //         }
    //     });
    //
    //     /**
    //      * Get all ULB for based on selected Grade and Grade-Type
    //      * @returns {*}
    //      */
    //     $scope.getULBForGrade = function () {
    //         var searchFilter = {};
    //         searchFilter[$scope.queryData.gradeType] = $scope.queryData.grade;
    //         return $filter('filter')($scope.ulbList, searchFilter);
    //
    //     };
    //     /**
    //      * Get labels for null option, sets a new value based on the gradeType selected
    //      * @returns {*}
    //      */
    //     $scope.getDefaultGradeTypeLabel = function () {
    //         if ('district' === $scope.queryData.gradeType) return $filter('translate')('vassar.directives.revenue.detail.option.label.All Districts');
    //         if ('region' === $scope.queryData.gradeType) return $filter('translate')('vassar.directives.revenue.detail.option.label.All Regions');
    //         if ('grade' === $scope.queryData.gradeType) return $filter('translate')('vassar.directives.revenue.detail.option.label.All ULB Types');
    //     };
    //
    //     /**
    //      * Process request
    //      */
    //
    //     $scope.submit = function () {
    //         if($scope.queryData.selectedULB){
    //           $state.go('zone', {id: $scope.queryData.selectedULB,type:"ULB",
    //               fromDate:$scope.queryData.fromDate.toLocaleDateString(),
    //               toDate:$scope.queryData.toDate},{notify:false, reload:false});
    //             $scope.callfunc({key:$scope.queryData.selectedULB,type:"ULB",
    //                 from:$scope.queryData.fromDate,to:$scope.queryData.toDate
    //             });
    //         }
    //         else {
    //           $state.go('zone', {
    //               id: $scope.queryData.grade, type: $scope.queryData.gradeType.toUpperCase(),
    //               fromDate: $scope.queryData.fromDate,
    //               toDate: $scope.queryData.toDate},{notify:false, reload:false});
    //             $scope.callfunc({key:$scope.queryData.grade,type:$scope.queryData.gradeType.toUpperCase(),
    //                 from:$scope.queryData.fromDate,to:$scope.queryData.toDate
    //             });
    //
    //         }
    //     };
    //
    //     $scope.send = function (type,key) {
    //         if(type && key){
    //             $state.go('zone', {id: key,type:type},{notify:false, reload:false});
    //         }
    //         else {
    //             $state.go('home', {},{notify:false, reload:false});
    //         }
    //         $scope.callfunc({key:key,type:type})
    //     };
    //
    //     $scope.call2 = function (key,type) {
    //         $scope.callfunc({key:key,type:type})
    //     };
    //
    //
    //     $scope.getULBGradeDesc = function (ulbGrade) {
    //         if(ulbGrade === "I") return "Grade I";
    //         else if(ulbGrade === "II") return "Grade II";
    //         else if(ulbGrade === "III") return "Grade III";
    //         else if(ulbGrade === "NP") return "Nagar Panchayat";
    //         else if(ulbGrade === "Special") return "Special Grade";
    //         else if(ulbGrade === "Corp") return "Corporation";
    //         else return ulbGrade;
    //     };
    //
    // }

    // angular
    //     .module('madApp')
    //     .directive('collectionSummary', collectionSummaryDirective);
    //
    // function collectionSummaryDirective() {
    //     var directive = {
    //         restrict: 'A',
    //         scope: {
    //             title: "@title",
    //             totalSummary: "=totalSummary"
    //         },
    //         controller: CollectionSummaryDirectiveController,
    //         templateUrl: 'app/home/collection.summary.template.html',
    //         link: linkFunc,
    //
    //
    //     };
    //
    //     return directive;
    //
    //     function linkFunc(scope, element, attrs) {
    //         //scope.title = attrs.title;
    //         //scope.trend = attrs.summary;
    //     }
    // }
    //
    // angular
    //     .module('madApp')
    //     .controller('CollectionSummaryDirectiveController', CollectionSummaryDirectiveController)
    // ;
    //
    // CollectionSummaryDirectiveController.$inject = ['$scope', '$state', '$stateParams', '$filter', 'ParseLinks', 'AlertService'];
    // function CollectionSummaryDirectiveController($scope, $state, $stateParams, $filter, ParseLinks, AlertService) {
    //     $scope.parseFloat = parseFloat;
    // }
    //
    //
    // angular
    //     .module('madApp')
    //     .directive('revenueTable', revenueTableDirective);
    //
    // function revenueTableDirective() {
    //     var directive = {
    //         restrict: 'A',
    //         templateUrl: 'app/home/revenuetable.template.html',
    //         link: linkFunc,
    //         controller: 'RevenueTableDirectiveController',
    //         scope: {
    //             //title: "@title",
    //             details: "=details",
    //             totalSummary: "=totalSummary",
    //             callfunc:"&callfunc"
    //         }
    //     };
    //
    //     return directive;
    //
    //     function linkFunc(scope, element, attrs) {
    //         //scope.title = attrs.title;
    //         //scope.revenue = attrs.summary;
    //     }
    // }

    // angular
    //     .module('madApp')
    //     .controller('RevenueTableDirectiveController', RevenueTableDirectiveController)
    // ;
    //
    // RevenueTableDirectiveController.$inject = ['$scope', '$state', '$stateParams', '$filter', 'ParseLinks', 'AlertService','StateRevenueService',];
    // function RevenueTableDirectiveController($scope, $state, $stateParams, $filter, ParseLinks, AlertService, StateRevenueService) {
    //     $scope.parseFloat = parseFloat;
    //
    //     $scope.submit = function (key) {
    //          $state.go('zone', {id: key,type:$scope.details.locType},{notify:false, reload:false});
    //          $scope.callfunc({key: key,type:$scope.details.locType})
    //         //$state.go('zone', {id: key,type:$scope.details.locType});
    //     };
    //
    //
    //     $scope.compare = function (v1,v2) {
    //         // console.log(a);
    //         // console.log(b);
    //         //return a.value < b.value;
    //         if (v1.type !== 'string' || v2.type !== 'string') {
    //             return (v1.index < v2.index) ? -1 : 1;
    //         }
    //
    //         // Compare strings alphabetically, taking locale into account
    //         return v1.value.localeCompare(v2.value);
    //         //return 1;
    //     //| orderBy:'this['+record.sortIndex+'].value':record.sortOrder:compare
    //         //ng-click="setSortOrder($index,$parent.$index)"
    //     };
    //
    //     $scope.setSortOrder = function(index,parentIndex){
    //         if($scope.details.records[parentIndex].sortIndex ===index){
    //             $scope.details.records[parentIndex].sortOrder = !$scope.details.records[parentIndex].sortOrder;
    //         }else{
    //             $scope.details.records[parentIndex].sortOrder = false;
    //         }
    //         $scope.details.records[parentIndex].sortIndex = index;
    //     };
    // }



})();
