(function () {
    'use strict';


    angular
        .module('madApp')
        .factory('StateRevenueService', StateRevenueService);

    StateRevenueService.$inject = ['$resource'];

    function StateRevenueService($resource) {

        var service = {
            getStateSummariesData: getStateSummariesData,
            getChildCollectionData:getChildCollectionData,
            getAllULBData:getAllULBData,
            getChildDCBData:getChildDCBData,
            getCollectionTableData:getCollectionTableData,
            getChildReceiptsData:getChildReceiptsData,
            getTop10Data:getTop10Data,
            getBottom10Data:getBottom10Data,
            getTop100DefaultersData:getTop100DefaultersData
        };
        return service;

        function getStateSummariesData() {
            var resourceUrl = 'api/stateSummaries';

            return $resource(resourceUrl, {}, {
                'query': {
                    method: 'GET', isArray: false,
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                        }
                        return data;
                    }
                }
            });
        }


        function getChildCollectionData() {
            var resourceUrl = 'api/childCollectionData';

            return $resource(resourceUrl, {}, {
                'query': {
                    method: 'GET', isArray: false,
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                        }
                        return data;
                    }
                }
            });
        }

        function getChildDCBData() {
            var resourceUrl = 'api/childDCBData';

            return $resource(resourceUrl, {}, {
                'query': {
                    method: 'GET', isArray: false,
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                        }
                        return data;
                    }
                }
            });
        }

        function getChildReceiptsData() {
            var resourceUrl = 'api/childReceiptsData';

            return $resource(resourceUrl, {}, {
                'query': {
                    method: 'GET', isArray: false,
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                        }
                        return data;
                    }
                }
            });
        }

        function getTop10Data() {
            var resourceUrl = 'api/top10Data';

            return $resource(resourceUrl, {}, {
                'query': {
                    method: 'GET', isArray: false,
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                        }
                        return data;
                    }
                }
            });
        }

        function getTop100DefaultersData() {
            var resourceUrl = 'api/top100DefaultersData';

            return $resource(resourceUrl, {}, {
                'query': {
                    method: 'GET', isArray: false,
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                        }
                        return data;
                    }
                }
            });
        }

        function getBottom10Data() {
            var resourceUrl = 'api/bottom10Data';

            return $resource(resourceUrl, {}, {
                'query': {
                    method: 'GET', isArray: false,
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                        }
                        return data;
                    }
                }
            });
        }

        function getAllULBData() {
            var resourceUrl = 'api/getAllULBData';

            return $resource(resourceUrl, {}, {
                'query': {
                    method: 'GET', isArray: false,
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                        }
                        return data;
                    }
                }
            });
        }

        function getCollectionTableData() {
            var resourceUrl = 'api/collectionTableData';

            return $resource(resourceUrl, {}, {
                'query': {
                    method: 'GET', isArray: false,
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                        }
                        return data;
                    }
                }
            });
        }

    };


    angular
        .module('madApp')
        .controller('HomeController', HomeController)
        .filter('positive', function() {
            return function(input) {
                if (!input) {
                    return 0;
                }

                return Math.abs(input);
            };
        })
        .filter( 'camelCase', function () {
           return function ( input )
            {
                if (input) {
                    return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
                }

            };

        })
        .filter( 'getLastYear', function () {
            return function ( input )
            {
                if (input) {
                    var start = new Date(input);
                    var y = start.getFullYear();
                    return start.setFullYear(y-1);
                }

            };

        });

    var activetab = 0;

    HomeController.$inject = ['$scope','Principal', 'LoginService', 'StateRevenueService', 'AlertService', '$state', '$filter'];

    function HomeController($scope,Principal, LoginService, StateRevenueService, AlertService, $state, $filter) {
        var vm = this;
        vm.account = null;
        vm.isAuthenticated = null;
        // vm.login = LoginService.open;
        // vm.register = register;
        vm.stateSummaryData = {};
        vm.collectionTrendData ={};
        vm.collectionTotalSummary ={};
        vm.collectionTableData ={};
        vm.ulbList = [];
        vm.firstcall = 0;

        // $scope.$on('authenticationSuccess', function () {
        //     getAccount();
        // });
        //
        // getAccount();
        // stateSummary();
        // getAllULBData();

        // $scope.setActiveTab = function(input){
        //     $state.params.fromDate=null;
        //     $state.params.toDate=null;
        //     if(vm.firstcall != 0){
        //       $state.params.id=null;
        //       $state.params.type=null;
        //       $state.go('home', {},{notify:false, reload:false});
        //     }
        //     if(input===0)
        //         $scope.callCollection();
        //     else if(input===1)
        //         $scope.callDCB();
        //     else if(input===2)
        //         $scope.callreceipts();
        //     else if(input===3)
        //         $scope.calltop10();
        //     else if(input===4)
        //         $scope.callbottom10();
        //     else if(input===5)
        //         $scope.calldefaulters();
        //     activetab = input;
        //
        // };

        // $scope.getActiveTab = function(){
        //     return activetab;
        // };
        //
        // function setdates(from,to){
        //     if (from) {
        //         $state.params.fromDate = from;
        //     }
        //     if (to) {
        //         $state.params.toDate =  to;
        //     }
        // }

        // $scope.callCollection =function(key,type,from,to){
        //     setdates(from,to);
        //     collectionData(key,type);
        // };
        // $scope.callDCB =function(key,type,from,to){
        //     setdates(from,to);
        //     dcbData(key,type);
        // };
        // $scope.callreceipts =function(key,type,from,to){
        //     setdates(from,to);
        //     receiptsData(key,type);
        // };
        // $scope.calltop10 =function(key,type,from,to){
        //     setdates(from,to);
        //     top10Data(key,type);
        // };
        // $scope.callbottom10 =function(key,type,from,to){
        //     setdates(from,to);
        //     bottom10Data(key,type);
        // };
        // $scope.calldefaulters =function(key,type,from,to){
        //     setdates(from,to);
        //     top100DefaultersData(key,type);
        // };


        function getAccount() {

            // Principal.identity().then(function (account) {
            //     vm.account = account;
            //     vm.isAuthenticated = Principal.isAuthenticated;
            // });
            //stateSummary();
            //getAllULBData();
            // collectionData();
            // dcbData();
            // receiptsData();
            // top10Data();
            // bottom10Data();
            // top100DefaultersData();
        }

        function stateSummary() {
            StateRevenueService.getStateSummariesData().query(null, onSuccess, onError);
            function onSuccess(data) {
                vm.stateSummaryData = {
                    formattedDate: $filter('date')(data.calculatedOn, "dd-MMM-yyyy"),
                    formattedTime: $filter('date')(data.calculatedOn, "hh:mm a"),
                    totalRevenueAmount: ($filter('number')(data.totalRevenueCollected.amount)),
                    totalRevenueUnit: "Crs",
                    propertyTax: {
                        collected: {
                            amount: ($filter('number')(data.propertyTax.collected.amount)),
                            amountUnit: 'Crs',
                            variance: (data.propertyTax.varianceOverLastYear),
                            achievement: (data.propertyTax.achievementPercent)
                        },
                        lastYear: {
                            amount: ($filter('number')(data.propertyTax.collectedLastYear.amount)),
                            amountUnit: 'Crs'
                        },
                        target: {amount: ($filter('number')(data.propertyTax.target.amount)), amountUnit: 'Crs'}
                    },
                    waterCharges: {
                        collected: {
                            amount: ($filter('number')(data.waterCharges.collected.amount)),
                            amountUnit: 'Crs',
                            variance: (data.waterCharges.varianceOverLastYear),
                            achievement: (data.waterCharges.achievementPercent)
                        },
                        lastYear: {
                            amount: ($filter('number')(data.waterCharges.collectedLastYear.amount)),
                            amountUnit: 'Crs'
                        },
                        target: {amount: ($filter('number')(data.waterCharges.target.amount)), amountUnit: 'Crs'}

                    },
                    others: {
                        collected: {
                            amount: ($filter('number')(data.others.collected.amount)),
                            amountUnit: 'Crs',
                            variance: (data.others.varianceOverLastYear),
                            achievement: (data.others.achievementPercent)
                        },
                        lastYear: {
                            amount: ($filter('number')(data.others.collectedLastYear.amount)),
                            amountUnit: 'Crs'
                        },
                        target: {amount: ($filter('number')(data.others.target.amount)), amountUnit: 'Crs'}
                     }
                };

            }

            function onError(error) {
                AlertService.error(error.data.message);
            }

        }

        function convertDateTimezone(input){
            if(input){
                var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
                var localISOTime = (new Date(input - tzoffset)).toISOString();
                return localISOTime;
            }else{
                return input;
            }

        }

        function collectionData(key,type) {
            var query = {};

            if ($state.params.id && vm.firstcall === 0) {
                query.locKey = $state.params.id;
            }
            if ($state.params.type && vm.firstcall === 0) {
                query.locationType = $state.params.type;
            }
            if ($state.params.fromDate) {
                query.fromDate = convertDateTimezone($state.params.fromDate);
            }
            if ($state.params.toDate) {
                query.toDate =  convertDateTimezone($state.params.toDate);
            }

            if (type) {
                query.locationType = type;
            }
            if (key) {
                query.locKey = key;
            }

            StateRevenueService.getChildCollectionData().query(query, onSuccess, onError);
            function onSuccess(data) {
                vm.collid="coll_line";
                if (data.revenueRecords) {
                    vm.revenueRecords = data.revenueRecords;
                }
                vm.collectionTrendData = {
                    formattedFromDate: $filter('date')(data.trendData.fromDate, "dd-MMM-yyyy"),
                    formattedToDate: $filter('date')(data.trendData.toDate, "dd-MMM-yyyy"),
                    monthArray: ['Apr', 'May', 'Jun',
                        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar'],
                    chartData: data.trendData.chartData
                };
                vm.collectionTotalSummary ={
                    formattedFromDate: $filter('date')(data.fromDate, "dd-MMM-yyyy"),
                    formattedToDate: $filter('date')(data.toDate, "dd-MMM-yyyy"),
                    totalSummary:data.totalSummary
                };
                vm.collectionTableData = {
                    records:data.revenueRecords,
                    locType:data.locationType,
                    formattedFromDate: data.fromDate,
                    formattedToDate: data.toDate,
                };
                vm.colloptions= {
                    xAxisArray: ['Apr', 'May', 'Jun',
                        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar'],
                    seriesData: data.trendData.chartData
                };
                vm.collkey=  query.locKey;
                vm.colltype = query.locationType;
                vm.firstcall+=1;

            }

            function onError(error) {
                AlertService.error(error.data.message);
                vm.firstcall+=1;
            }

        }

        function getAllULBData() {
            if ($state.params.id && $state.params.type) {
                vm.key=  $state.params.id;
                vm.type = $state.params.type;
            }
            StateRevenueService.getAllULBData().query(null, onSuccess, onError);
            function onSuccess(data) {
                if(data.data){
                    vm.ulbList = data.data;
                }
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function dcbData(key,type) {
            var query = {};
            if ($state.params.id && vm.firstcall === 0) {
                query.locKey = $state.params.id;
            }
            if ($state.params.type && vm.firstcall === 0) {
                query.locationType = $state.params.type;
            }
            if ($state.params.fromDate) {
                query.fromDate = convertDateTimezone($state.params.fromDate);
            }
            if ($state.params.toDate) {
                query.toDate =  convertDateTimezone($state.params.toDate);
            }

            if (type) {
                query.locationType = type;
            }
            if (key) {
                query.locKey = key;
            }

            StateRevenueService.getChildDCBData().query(query, onSuccess, onError);
            function onSuccess(data) {
                vm.dcbid="dcb_line";
                if (data.revenueRecords) {
                    vm.dcbRecords = data.revenueRecords;
                }
                vm.dcbTrendData = {
                    formattedFromDate: $filter('date')(data.trendData.fromDate, "dd-MMM-yyyy"),
                    formattedToDate: $filter('date')(data.trendData.toDate, "dd-MMM-yyyy"),
                    monthArray: ['Apr', 'May', 'Jun',
                        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar'],
                    chartData: data.trendData.chartData
                };
                vm.dcbTotalSummary ={
                    formattedFromDate: $filter('date')(data.fromDate, "dd-MMM-yyyy"),
                    formattedToDate: $filter('date')(data.toDate, "dd-MMM-yyyy"),
                    totalSummary:data.totalSummary
                };
                vm.dcbTableData = {
                    records:data.revenueRecords,
                    locType:data.locationType,
                    formattedFromDate: $filter('date')(data.fromDate, "dd-MMM-yyyy"),
                    formattedToDate: $filter('date')(data.toDate, "dd-MMM-yyyy")
                };
                vm.dcboptions= {
                    xAxisArray: ['Apr', 'May', 'Jun',
                        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar'],
                    seriesData: data.trendData.chartData
                    // seriesData: [
                    //     {name: "2016", data: [7.0, 6.9, 33.2, 2.5, 33.6, 88.2, null, null, null, null, null, null]},
                    //     {name: "2015", data: [0.2, 0.8, 5.7, 11.3, 17.0, 22.0, 24.8, 24.1, 20.1, 14.1, 8.6, 2.5]},
                    //     {name: "2014", data: [0.9, 0.6, 3.5, 8.4, 13.5, 17.0, 18.6, 17.9, 14.3, 9.0, 3.9, 1.0]}
                    // ]
                }
                vm.dcbkey=  query.locKey;
                vm.dcbtype = query.locationType;

            }

            function onError(error) {
                AlertService.error(error.data.message);
            }

        }

        function receiptsData(key,type) {
            var query = {};

            if ($state.params.id && vm.firstcall === 0) {
                query.locKey = $state.params.id;
            }
            if ($state.params.type && vm.firstcall === 0) {
                query.locationType = $state.params.type;
            }
            if ($state.params.fromDate) {
                query.fromDate = convertDateTimezone($state.params.fromDate);
            }
            if ($state.params.toDate) {
                query.toDate =  convertDateTimezone($state.params.toDate);
            }

            if (type) {
                query.locationType = type;
            }
            if (key) {
                query.locKey = key;
            }
            StateRevenueService.getChildReceiptsData().query(query, onSuccess, onError);
            function onSuccess(data) {
                vm.receiptsid="receipts_line";
                if (data.revenueRecords) {
                    vm.receiptsRecords = data.revenueRecords;
                }
                vm.receiptsTrendData = {
                    formattedFromDate: $filter('date')(data.trendData.fromDate, "dd-MMM-yyyy"),
                    formattedToDate: $filter('date')(data.trendData.toDate, "dd-MMM-yyyy"),
                    monthArray: ['Apr', 'May', 'Jun',
                        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar'],
                    chartData: data.trendData.chartData
                };
                vm.receiptsTotalSummary ={
                    formattedFromDate: $filter('date')(data.fromDate, "dd-MMM-yyyy"),
                    formattedToDate: $filter('date')(data.toDate, "dd-MMM-yyyy"),
                    totalSummary:data.totalSummary
                };
                vm.receiptsTableData = {
                    records:data.revenueRecords,
                    locType:data.locationType,
                    formattedFromDate: $filter('date')(data.fromDate, "dd-MMM-yyyy"),
                    formattedToDate: $filter('date')(data.toDate, "dd-MMM-yyyy")
                };
                vm.receiptsoptions= {
                    xAxisArray: ['Apr', 'May', 'Jun',
                        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar'],
                    seriesData: data.trendData.chartData
                };

                vm.receiptskey=  query.locKey;
                vm.receiptstype = query.locationType;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }

        }

        function top10Data(key,type) {
            var query = {};

            if ($state.params.id && vm.firstcall === 0) {
                query.locKey = $state.params.id;
            }
            if ($state.params.type && vm.firstcall === 0) {
                query.locationType = $state.params.type;
            }
            if ($state.params.fromDate) {
                query.fromDate = convertDateTimezone($state.params.fromDate);
            }
            if ($state.params.toDate) {
                query.toDate =  convertDateTimezone($state.params.toDate);
            }

            if (type) {
                query.locationType = type;
            }
            if (key) {
                query.locKey = key;
            }
            StateRevenueService.getTop10Data().query(query, onSuccess, onError);
            function onSuccess(data) {
                if (data.receiptsRecords) {
                    vm.top10Records = data.revenueRecords;
                }
                vm.top10TrendData = {};
                vm.top10TotalSummary ={};
                vm.top10TableData = {
                    records:data.revenueRecords,
                    locType:data.locationType,
                    formattedFromDate: $filter('date')(data.fromDate, "dd-MMM-yyyy"),
                    formattedToDate: $filter('date')(data.toDate, "dd-MMM-yyyy"),
                };
                vm.top10options= {}
                vm.top10key=  query.locKey;
                vm.top10type = query.locationType;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }

        }

        function bottom10Data(key,type) {
            var query = {};

            if ($state.params.id && vm.firstcall === 0) {
                query.locKey = $state.params.id;
            }
            if ($state.params.type && vm.firstcall === 0) {
                query.locationType = $state.params.type;
            }
            if ($state.params.fromDate) {
                query.fromDate = convertDateTimezone($state.params.fromDate);
            }
            if ($state.params.toDate) {
                query.toDate =  convertDateTimezone($state.params.toDate);
            }

            if (type) {
                query.locationType = type;
            }
            if (key) {
                query.locKey = key;
            }
            StateRevenueService.getBottom10Data().query(query, onSuccess, onError);
            function onSuccess(data) {
                if (data.bottom10Records) {
                    vm.bottom10Records = data.revenueRecords;
                }
                vm.bottom10TrendData = {};
                vm.bottom10TotalSummary ={};
                vm.bottom10TableData = {
                    records:data.revenueRecords,
                    locType:data.locationType,
                    formattedFromDate: $filter('date')(data.fromDate, "dd-MMM-yyyy"),
                    formattedToDate: $filter('date')(data.toDate, "dd-MMM-yyyy")
                };
                vm.bottom10options= {}
                vm.bottom10key=  query.locKey;
                vm.bottom10type = query.locationType;

            }

            function onError(error) {
                AlertService.error(error.data.message);
            }

        }

        function top100DefaultersData(key,type) {
            var query = {};

            if ($state.params.id && vm.firstcall === 0) {
                query.locKey = $state.params.id;
            }
            if ($state.params.type && vm.firstcall === 0) {
                query.locationType = $state.params.type;
            }
            if ($state.params.fromDate) {
                query.fromDate = convertDateTimezone($state.params.fromDate);
            }
            if ($state.params.toDate) {
                query.toDate =  convertDateTimezone($state.params.toDate);
            }

            if (type) {
                query.locationType = type;
            }
            if (key) {
                query.locKey = key;
            }
            StateRevenueService.getTop100DefaultersData().query(query, onSuccess, onError);
            function onSuccess(data) {
                if (data.receiptsRecords) {
                    vm.top100DefaultersRecords = data.revenueRecords;
                }
                vm.top100DefaultersTrendData = {};
                vm.top100DefaultersTotalSummary ={};
                vm.top100DefaultersTableData = {
                    records:data.revenueRecords,
                    locType:data.locationType,
                    formattedFromDate: $filter('date')(data.fromDate, "dd-MMM-yyyy"),
                    formattedToDate: $filter('date')(data.toDate, "dd-MMM-yyyy")
                };
                vm.top100Defaultersoptions= {}
                vm.top100Defaulterskey=  query.locKey;
                vm.top100Defaulterstype = query.locationType;

            }

            function onError(error) {
                AlertService.error(error.data.message);
            }

        }


        function register() {
            $state.go('register');
        }
    }
})();
