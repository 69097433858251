(function () {
    'use strict';
    angular.module('madApp')
        .factory('dorStateSummariesService', dorStateSummariesService);

    dorStateSummariesService.$inject = ['$resource'];
    /**
     *
     * @param $resource
     * @returns {*}
     */
    function dorStateSummariesService($resource) {
        return {
            stateSummaries: stateSummaries()
        };
        function stateSummaries() {
            var resourceUrl = 'api/stateSummaries';

            return $resource(resourceUrl, {}, {
                'summary': {
                    method: 'GET', cache: true, isArray: false,
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                        }
                        return data;
                    }
                }
            });
        }
    }
})();
