angular
    .module('madApp')
    .directive('dorStateSummary', dorStateSummary);

//revenueSummaryDirective.$inject = [];

function dorStateSummary() {
    var directive = {
        restrict: 'A',
        templateUrl: 'app/vl/dashboards/directives/summary/state.summary.html',
        scope: {
            title: "@title",
            icon: "&icon",
            summary: "=summary",
            url: "@url"
        },
        link: linkFunc
    };

    return directive;

    function linkFunc(scope, element, attrs) {
        scope.icon = attrs.icon;
        scope.title = attrs.title;

        element.bind('click', function(event) {
          if(!scope.url) {
            event.preventDefault();
          }
        });
    }
}
