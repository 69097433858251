(function () {
    'use strict';
    angular.module('madApp')
    .factory('RevenueServices', RevenueServices);
    RevenueServices.$inject = ['$resource'];

    function RevenueServices($resource) {
        var statecollection ={
        };
        var propertyType = null;
        var service =  {
            getCollections: getCollections,
            getDCB: getDCB,
            getReceipts: getReceipts,
            getTop10: getTop10,
            getBottom10: getBottom10,
            getTop100Defaulters: getTop100Defaulters,
            getCollectionTableData: getCollectionTableData,
            listAllUlBs:listAllUlBs,
            setstate:setstate,
            getstate:getstate,
            getPropertyType:getPropertyType,
            setPropertyType:setPropertyType,
            getCollectionRankingData: getCollectionRankingData
        };
        return service;

        function setPropertyType(input) {
            propertyType = input;
        }

        function getPropertyType() {
            return propertyType;
        }

        function setstate(input,type) {
            statecollection[type] = input;
        }

        function getstate(type) {
            return statecollection[type];
        }

        function getCollections() {
            var resourceUrl = 'api/childCollectionData';

            return $resource(resourceUrl, {}, {
                'query': {
                    method: 'GET',  cache: true, isArray: false,
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                        }
                        return data;
                    }
                }
            });
        }

        function getDCB() {
            var resourceUrl = 'api/childDCBData';

            return $resource(resourceUrl, {}, {
                'query': {
                    method: 'GET', cache: true, isArray: false,
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                        }
                        return data;
                    }
                }
            });
        }

        function getReceipts() {
            var resourceUrl = 'api/childReceiptsData';

            return $resource(resourceUrl, {cache: true}, {
                'query': {
                    method: 'GET', cache:true, isArray: false,
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                        }
                        return data;
                    }
                }
            });
        }

        function getTop10() {
            var resourceUrl = 'api/top10Data';

            return $resource(resourceUrl, {}, {
                'query': {
                    method: 'GET', isArray: false,
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                        }
                        return data;
                    }
                }
            });
        }
        function getBottom10() {
            var resourceUrl = 'api/bottom10Data';

            return $resource(resourceUrl, {}, {
                'query': {
                    method: 'GET', cache: true, isArray: false,
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                        }
                        return data;
                    }
                }
            });
        }

        function getTop100Defaulters() {
            var resourceUrl = 'api/top100DefaultersData';

            return $resource(resourceUrl, {}, {
                'query': {
                    method: 'GET', cache: true, isArray: false,
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                        }
                        return data;
                    }
                }
            });
        }

        function getCollectionRankingData() {
            var resourceUrl = 'api/collectionRankingData';

            return $resource(resourceUrl, {}, {
                'query': {
                    method: 'GET', cache: true, isArray: false,
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                        }
                        return data;
                    }
                }
            });
        }

        function getCollectionTableData() {
            var resourceUrl = 'api/collectionTableData';

            return $resource(resourceUrl, {cache: true}, {
                'get': {
                    method: 'GET',  cache: true, isArray: false,
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                        }
                        return data;
                    }
                }
            });
        }

        function listAllUlBs() {
            var resourceUrl = 'api/getAllULBData';

            return $resource(resourceUrl, {}, {
                'query': {
                    method: 'GET', isArray: false, cache: true,
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                        }
                        return data;
                    }
                }
            });
        }

    }
})();
