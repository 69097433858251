angular
    .module('madApp')
    .directive('vlFilterForm', vlFilterForm);

vlFilterForm.$inject = ['$filter'];

function vlFilterForm($filter) {
    var directive = {
        restrict: 'A',
        templateUrl: 'app/vl/dashboards/directives/filterForm/filterForm.html',
        scope: {
            title: "@title",
            icon: "&icon",
            summary: "=summary",
            queryData: "=queryData",
            ulbList: "=ulbList",
            vlSubmit: "&",
            showDates: '=showDates',
            showProperty:"=showProperty",
            type:"=ftype",
            key:"=fkey",
            formHidden:"=formHidden",
            ulbtype: "=ulbtype",
            officertype: "=officertype"
        },
        link: linkFunc,
        controller: 'vlFilterFormController'
    };

    return directive;

    function linkFunc(scope, element, attrs) {
        scope.icon = attrs.icon;
        scope.title = attrs.title;
    }
}
